<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>组合项目管理</el-breadcrumb-item>
        <el-breadcrumb-item>组合项目详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <el-card shadow="always" class="detail-container">
      <div>
        <div class="title">组合项目详情</div>
        <div style="padding: 20px">
          <div style="display: flex; align-items: baseline; line-height: 52px">
            <div class="item-key">组合项目名称</div>
            <div class="item-value">{{groupProjectName}}</div>
          </div>
          <div style="display: flex;marginBottom:20px ">
            <div class="item-key">组合项目持仓</div>
            <div class="item-value">
              <el-table :data="tableData" border style="width: 800px">
                <el-table-column align="center" prop="symbol" label="股票名称"> </el-table-column>
                <el-table-column align="center" prop="stockCode" label="股票代码"> </el-table-column>
                <el-table-column align="center" prop="initNumber" label="初始股票数量"> </el-table-column>
                <el-table-column align="center" prop="residueNumber" label="剩余股票数量"> </el-table-column>
                <el-table-column align="center" prop="totalYield" label="累计盈亏比例">
                    <template #default="scope">
                    <div style="fontWeight:bold" :style="{color:scope.row.totalYield>0?'#FF5555':'#368B11'}">
                    <span v-show="scope.row.totalYield>0">+</span>{{scope.row.totalYield}}<span v-if="scope.row.totalYield">%</span><span v-else>--</span></div>
                  </template>
                    
                </el-table-column>
                <el-table-column align="center" prop="close" label="操作">
                  <template #default="scope">
                    <div v-show="scope.row.close==='0'" @click="closing(scope.row)" style="fontWeight:bold;color:#0f40f5;cursor:pointer">平仓</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div style="display: flex; ">
            <div class="item-key">组合平仓记录</div>
            <div class="item-value">
              <el-table :data="closeData" border style="width: 800px">
                <el-table-column align="center" prop="createTime" label="时间"> </el-table-column>
                <el-table-column align="center" prop="stockName" label="股票名称"> </el-table-column>
                <el-table-column align="center" prop="stockCode" label="股票代码"> </el-table-column>
                <el-table-column align="center" prop="quantity" label="平仓股票数量"> </el-table-column>
                <el-table-column align="center" prop="residueQuantity" label="平仓剩余股票数量"> </el-table-column>
              </el-table>
            </div>
          </div>
        </div>

        <div class="btn-group">
          <div class="btn" @click="back">返回</div>
        </div>
      </div>
    </el-card>
    <el-dialog title="新增平仓记录" v-model="dialogVisible" :width="600">
      <el-form ref="form" label-position="right" label-width="141px" class="stock-form">
        <el-form-item label="平仓股票">
          <div style="marginLeft:40px">{{stockInfo.symbol}}</div>
        </el-form-item>
        <el-form-item label="当前股票数量">
          <div style="marginLeft:40px">{{stockInfo.residueNumber}}</div>
        </el-form-item>
        <el-form-item label="平仓股票数量" prop='content'>
          <div class="stockInfo" style="width: 450px;marginLeft:40px">
            <el-input v-model.number="quantity" @input="inputNumber" maxlength="12" placeholder="请输入本次平仓的股票数量" style="width: 280px"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="剩余股票数量">
          <div style="marginLeft:40px">{{residueQuantity}}</div>
        </el-form-item>
        <el-form-item label="参考估算平仓总金额(根据最新收盘价)">
          <div style="marginLeft:40px">{{total}}万元</div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="info" @click="confirmNode" style="background-color:#38a28a">提 交</el-button>
          <el-button @click="cancel">取 消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { formatterDate } from "@/utils/format";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from 'element-plus'
export default {
  setup(props, content) {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      content: [],
      analysis: [],
      tableData: [],
      closeData: [],
      dialogVisible: false,
      groupProjectName: '',
      stockInfo: {},
      formList: {
        content: "",
        id: '',
        datetime: "",
        isImportant: "",
        reason: "",
      },
      quantity: null,
      residueQuantity: 0,
      total: 0,
      price: 0
    });

    onMounted(() => {
      state.groupProjectName = route.query.groupProjectName
      //获取详情
      getDetail();
      getClosingRecord();
    });

    const back = () => {
      router.push({ path: "/projectManagement" });
    };
    const cancel = () => {
      state.dialogVisible = false;
    };
    const closing = (data) => {
      state.dialogVisible = true
      state.stockInfo = data
      state.quantity = null
      state.total = 0
      state.residueQuantity = 0
      getNewPrice()
    };

    const getDetail = () => {
      const id = route.query.id;
      proxy
        .$get("/cts/back/projectPortfolioManagement/positionInformation?groupId=" + id)
        .then((res) => {
          if (res.resultState == "200") {
            state.tableData = res.data
          }
        })
        .catch((err) => { });
    };
    const getNewPrice = () => {
      const id = route.query.id;
      proxy
        .$get("/cts/back/projectPortfolioManagement/newPrice?stockCode=" + state.stockInfo.stockCode)
        .then((res) => {
          if (res.resultState == "200") {
            state.price = res.data
          }
        })
        .catch((err) => { });
    };
    const getClosingRecord = () => {
      const id = route.query.id;
      const data = {
        content: id,
        field: "",
        flag: "",
        pageNo: 1,
        pageSize: 9999,
        sequence: true,
        stockCodes: []
      }
      proxy
        .$post("/cts/back/projectPortfolioManagement/closingRecord", data)
        .then((res) => {
          if (res.resultState == "200") {
            state.closeData = res.data.list
          }
        })
        .catch((err) => { });
    };
    const inputNumber = () => {
      if (!Number.isInteger(+state.quantity) || +state.quantity <= 0) {
        ElMessage({
          message: '请填写正整数',
          type: 'warning',
        })
        return
      }
      if (+state.quantity > state.stockInfo.residueNumber) {
        ElMessage({
          message: '平仓数量不能大于当前股票数量',
          type: 'warning',
        })
        return
      }
      state.residueQuantity = state.stockInfo.residueNumber - +state.quantity
      state.total = ((+state.quantity * state.price) / 10000).toFixed(4)
    }
    const confirmNode = () => {
      if (+state.quantity > 0 & +state.quantity <= +state.stockInfo.residueNumber) {
        const id = route.query.id;
        const data = {
          groupId: id,
          quantity: state.quantity,
          residueQuantity: state.residueQuantity,
          stockCode: state.stockInfo.stockCode,
          stockName: state.stockInfo.symbol
        }
          proxy
            .$post("/cts/back/projectPortfolioManagement/closing", data)
            .then((res) => {
              if (res.resultState == "200") {
                state.dialogVisible = false;
                getDetail();
                getClosingRecord();
              }
            })
          .catch((err) => { });
      } else {
        if (!Number.isInteger(+state.quantity) || +state.quantity <= 0) {
          ElMessage({
            message: '请填写正整数',
            type: 'warning',
          })
          return
        } else {
          ElMessage({
            message: '平仓数量不能大于当前股票数量',
            type: 'warning',
          })
        }

      }


    }

    return {
      ...toRefs(state),
      back,
      closing,
      inputNumber,
      confirmNode,
      cancel
    };
  },
};
</script>

<style lang="scss" scoped>
.detail-container {
  .title {
    font-weight: bold;
  }
  .item-key {
    width: 160px;
  }
  .item-value {
    // width: 500px;
    // word-break: break-all;
    // line-height: 22px;
    /deep/ .el-table th.el-table__cell {
      background-color: #efefef;
      color: #000;
      font-weight: bold;
    }
  }
  .btn-group {
    display: flex;
    .btn {
      width: 80px;
      border: 1px solid #dcdcdc;
      text-align: center;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;
      cursor: pointer;
      &.edit {
        background: #38a28a;
        color: #fff;
        margin-right: 15px;
      }
    }
  }
}
</style>
